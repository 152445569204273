import React from "react";
import {Navigation, Pagination, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import Box, {BoxProps} from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import 'swiper/css';
import 'swiper/css/navigation';

export interface CarouselProps {
  id: string;
  color?: BoxProps["color"];
  initialSlide?: number;
  loop?: boolean;
  navigation?: boolean;
  fullWidth?: boolean;
}

export default function Carousel({
  loop = true,
  navigation = true,
  fullWidth = false,
  initialSlide = 1,
  ...props
}: React.PropsWithChildren<CarouselProps>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const color = props.color || theme.palette.primary.contrastText

  const mobileStyles = {
    height: "40px",
    borderRadius: "50%",
    borderColor: color,
    borderStyle: "solid",
    borderWidth: "2px",
    padding: "1rem",
    margin: "1rem"
  }

  return (
    <Box sx={{position: "relative", maxWidth: "100%", overflow: "hidden", pt: fullWidth ? 0 : 5, pb: fullWidth ? 0 : 5}}>
      <Swiper
        modules={
          navigation ? [
            Navigation,
            Pagination
          ] : [
            Pagination,
            Autoplay
          ]
        }
        observer
        observeParents
        lazy
        loop={loop}
        navigation={navigation ? {
          prevEl: `.prev-${props.id}`,
          nextEl: `.next-${props.id}`
        } : undefined}
        centeredSlides
        centeredSlidesBounds
        initialSlide={initialSlide}
        pagination={{clickable: false}}
        breakpoints={{
          [theme.breakpoints.values.xs]: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          [theme.breakpoints.values.md]: {
            slidesPerView: fullWidth ? 1 : 1.2,
            spaceBetween: fullWidth ? 20 : 80
          },
          [theme.breakpoints.values.lg]: {
            slidesPerView: fullWidth ? 1 : 1.8,
            spaceBetween: fullWidth ? 20 : 80,
          },
          [theme.breakpoints.values.xl]: {
            slidesPerView: fullWidth ? 1 : 2.8,
            spaceBetween: fullWidth ? 20 : 100,
          }
        }}
        speed={1000}
        autoplay={navigation
          ? false
          : {
            delay: 2000,
            disableOnInteraction: false,

          }
        }
      >
        {
          props.children.map((child: React.ReactNode, index: number, children: Array<any>) => (
            <SwiperSlide key={index}>
              {slideData => (
                <Box sx={{
                  opacity: slideData.isActive ? 1 : 0.5,
                  zIndex: slideData.isActive ? 1 : 0,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center"
                }}>
                  {React.cloneElement(child)}
                  {
                    navigation && (
                      <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <Box
                          className={`prev-${props.id}`}
                          sx={{
                            display: !slideData.isNext && !slideData.isPrev ? "flex" : "none",
                            color,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "40px",
                            justifyContent: "center",
                            ...(isMobile ? mobileStyles : {
                              position: "absolute",
                              height: "100%",
                              left: "-2.75rem",
                              top: 0,
                            }),
                            ...(
                              !loop && index === 0 && { opacity: 0.5 }
                            )
                          }}
                        >
                          <ChevronLeft/>
                        </Box>
                        <Box
                          className={`next-${props.id}`}
                          sx={{
                            display: !slideData.isNext && !slideData.isPrev ? "flex" : "none",
                            color,
                            alignItems: "center",
                            cursor: "pointer",
                            width: "40px",
                            justifyContent: "center",
                            ...(isMobile ? mobileStyles : {
                              position: "absolute",
                              height: "100%",
                              right: "-2.75rem",
                              top: 0,
                            }),
                            ...(
                              !loop && index + 1 === children.length && { opacity: 0.4 }
                            )
                          }}
                        >
                          <ChevronRight/>
                        </Box>
                      </Box>
                    )
                  }
                </Box>
              )}
            </SwiperSlide>
          ))
        }
      </Swiper>

    </Box>
  )
}